import { APP_INITIALIZER, inject } from '@angular/core';
import { injectRpcClient } from '@nest/common/core/frontend';
import type { SaleOrderDto, SaleOrderLineDto, SalesChannelDto } from '@nest/domain/entities-metadata/dtos';
import { SaleOrderEntityName, SaleOrderLineEntityName, SalesChannelEntityName } from '@nest/domain/entities-metadata/dtos';
import { saleOrderRouter } from '@nest/domain/sale-orders/shared';
import { EntityDetailLinkPathRendererComponent, EntityMetadataService, EntityService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { from } from 'rxjs';
import { SaleOrderBuyerWidgetComponent, SaleOrderBuyerWidgetName } from './sale-order-buyer-widget.component';
import { SaleOrderRecipientWidgetComponent, SaleOrderRecipientWidgetName } from './sale-order-recipient-widget.component';
import { SaleOrderStateColorEntityPathRendererComponent } from './sale-order-state-color-path-render.component';

const saleOrderPaths = autoPathsFor<SaleOrderDto>();
const saleOrderLinePaths = autoPathsFor<SaleOrderLineDto>();
const saleChannelPaths = autoPathsFor<SalesChannelDto>();

export const SaleOrderDefaultElements = fieldsToElements(
	saleOrderPaths(['createdAt', 'documentNumber', 'buyer.name', 'buyer.salesman.name', 'calculateTax', 'state', 'reservationState', 'buyerNote', 'note', 'lines'])
);
export const SaleOrderLineDefaultElements = fieldsToElements(saleOrderLinePaths(['createdAt', 'saleOrder.documentNumber', 'code', 'text', 'quantity', 'price.netto']));
export const SalesChannelDefaultElements = fieldsToElements(
	saleChannelPaths([
		'name',
		'enabled',
		'isDropshipping',
		'party.name',
		'saleOrderDocumentType.name',
		'brokerMode',
		'defaultApprovedState',
		'defaultSalesman.name',
		'deliveryPricelist.name'
	])
);

export function provideSaleOrderEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityService = inject(EntityService);
				let client = injectRpcClient(saleOrderRouter);
				let entityMetadata = inject(EntityMetadataService);

				return () => {
					entityService.registerEntity({
						label: 'Objednávky přijaté',
						key: 'sale-orders',
						entityName: SaleOrderEntityName,
						previewLabelFn: item => `${item.documentNumber} (${item.foreignOrderNumber})`,
						linkFn: id => (id ? ['/sale-orders', 'detail', id] : ['/sale-orders']),
						searchFn: term => from(client.saleOrders.search({ term }))
					});

					entityMetadata.registerEntity<SaleOrderDto>({
						entityName: SaleOrderEntityName,
						defaultElements: SaleOrderDefaultElements
					});

					entityMetadata.registerEntity<SaleOrderLineDto>({
						entityName: SaleOrderLineEntityName,
						defaultElements: SaleOrderLineDefaultElements
					});

					entityMetadata.registerEntity<SalesChannelDto>({
						entityName: SalesChannelEntityName,
						defaultElements: SalesChannelDefaultElements
					});

					entityMetadata.registerEntityPathRenderer(
						SaleOrderEntityName,
						saleOrderPaths(['state', 'fulfilmentState', 'reservationState']),
						SaleOrderStateColorEntityPathRendererComponent
					);

					entityMetadata.registerEntityPathRenderer(SaleOrderEntityName, saleOrderPaths(['documentNumber', 'foreignOrderNumber']), EntityDetailLinkPathRendererComponent);

					entityMetadata.registerEntityWidget({
						entityName: SaleOrderEntityName,
						label: 'Odběratel',
						widgetName: SaleOrderBuyerWidgetName,
						fields: saleOrderPaths(['buyer.name', 'currency']),
						displayType: 'value',
						widgetComponentType: SaleOrderBuyerWidgetComponent
					});

					entityMetadata.registerEntityWidget({
						entityName: SaleOrderEntityName,
						label: 'Příjemce',
						widgetName: SaleOrderRecipientWidgetName,
						fields: saleOrderPaths(['recipientSnapshot']),
						displayType: 'value',
						widgetComponentType: SaleOrderRecipientWidgetComponent
					});
				};
			}
		}
	];
}
