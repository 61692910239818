import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SaleOrderDto } from '@nest/domain/entities-metadata/dtos';
import { Entity, EntityElement } from '@nest/uikit/entity-presentation/ui';
import { ICON_IMPORTS } from '@nest/uikit/icon/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { provideIcons } from '@ng-icons/core';
import { iconoirMapPin } from '@ng-icons/iconoir';

export const SaleOrderStateTimelineWidgetName = 'SaleOrderStateTimeline';
export const SaleOrderStateTimelineWidgetFields = autoPathsFor<SaleOrderDto>()(['state', 'fulfilmentState', 'reservationState', 'outboundState']);

@Component({
	standalone: true,
	templateUrl: './sale-order-state-timeline-widget.component.html',
	styles: `
		:host {
			@apply block p-2;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ICON_IMPORTS],
	providers: [provideIcons({ iconoirMapPin })]
})
export class SaleOrderStateTimelineWidgetComponent<E extends Entity> {
	entityName = input.required<string>();
	element = input.required<EntityElement>();
	entity = input.required<Pick<SaleOrderDto, 'state' | 'fulfilmentState' | 'reservationState' | 'outboundState'>>();
}
